import './src/fonts/font-face.css';

export { wrapPageElement, wrapRootElement } from './gatsby-shared';

export const onRouteUpdate = () => {
  if (typeof window !== 'undefined' && mhct) {
    // Check if the mhct object exists
    mhct.reload();
  }
};

export const onInitialClientRender = () => {
  if (typeof window !== 'undefined' && mhct) {
    // Check if the mhct object exists
    mhct.reload();
  }
};
