import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BaseLayout } from './src/layouts/Base';
import theme from './src/styles/tokens';
import { PageProvider } from './src/context/pageContext';
import { ScriptsBefore } from './src/components/ScriptsBefore';

const queryClient = new QueryClient();

export const wrapPageElement = ({ element, props }) => {
  const pageID = props?.data?.page?.remoteId;
  return (
    <QueryClientProvider client={queryClient}>
      <PageProvider pageID={pageID}>
        <BaseLayout {...props}>{element}</BaseLayout>
      </PageProvider>
    </QueryClientProvider>
  );
};

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <ScriptsBefore />
    {element}
  </ThemeProvider>
);

export const onRenderBody = ({ setHeadComponents, setHtmlAttributes }) => {
  setHeadComponents([
    <script
      key="mediahawk-script"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          var _mhct = _mhct || [];
          _mhct.push(['mhCampaignID','VA-14085']);
          !function(){
            var c = document.createElement('script');
            c.type = 'text/javascript';
            c.async = true;
            c.src = '//www.dynamicnumbers.mediahawk.co.uk/mhct.min.js';
            var i = document.getElementsByTagName('script')[0];
            i.parentNode.insertBefore(c, i);
          }();
        `,
      }}
    />,
  ]);
  setHtmlAttributes({ lang: 'en-GB' });
};
